import React from 'react'
import NetBankingComponent from '../../components/NetBanking/NetBankingComponent'
import Footer from '../../layouts/Footer/Footer'
import Header from '../../layouts/Header/Header'

function NetBanking() {
  return (
    <div>
        <Header/>
        <NetBankingComponent/>
        <Footer/>
    </div>
  )
}

export default NetBanking