import React from 'react'
import WalletComponent from '../../components/Wallet/WalletComponent'
import Footer from '../../layouts/Footer/Footer'
import Header from '../../layouts/Header/Header'

function Wallet() {
  return (
    <div>
        <Header/>
        <WalletComponent/>
        <Footer/>
    </div>
  )
}

export default Wallet