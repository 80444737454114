import { useWindowSize } from '@react-hook/window-size'
import React from 'react'
import frame from '../../../assets/Group 67.svg'

function ComingSoon() {
    const [ width,height] = useWindowSize()
    return (
      <div className='relative w-full'>
        
        <div className='grid content-center' style={{
          height: height - (height * 0.2 + height * 0.1)
        }}>
          <div className="flex flex-col justify-evenly items-center" >
            <p className='text-center' style={{
                fontFamily:  'Josefin Sans, sans-serif',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '53px',
                lineHeight: '53px',
                textAlign: 'center',
                color:'#F2A71B'
            }}>Coming Soon</p>
            <img style={{
              width: '35%',
              height: '33px',
              marginTop : '25px'
            }} src={frame} alt="Coming Soon" />
          </div>
        </div>
      </div>
    )
}

export default ComingSoon