import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Link } from 'react-router-dom';
function Header() {
  return (
    <div className='pl-8 h-20 w-full flex items-center' style={{color:'#ffffff',background:'linear-gradient(90deg, #F23322 0%, #F2A71B 100%)', fontFamily:'Jost'}}>
        <div>
            <Link to='/'>
                <ArrowBackIosIcon/>
            </Link>
            <span className='pl-2' style={{fontSize:'18px'}}>Pay</span>
        </div>
        <div className='pl-4' style={{fontSize:'24px', }}>
            <CurrencyRupeeIcon sx={{
                fontSize:'24',
                fontWeight:'700'
            }}/>
            <span className='pl-2'>1695</span>
        </div>
    </div>
  )
}

export default Header