import { useState } from "react";
import { Link } from 'react-router-dom'
import CreditCardIcon from '../assets/IconsBackground.svg'
import DebitCardIcon from '../assets/Icons Background(1).svg'
import UpiIcon from '../assets/Icons Background (2).svg'
import WalletIcon from '../assets/Icons Background (3).svg'
import NetBankIcon from '../assets/Icons Background (4).svg'
import EmiIcon from '../assets/Icons Background (5).svg'
import BnplIcon from '../assets/Bnpl icon.svg'
const App = () => {

    const Menus = [
        { id: 1, title: "Credit Card", src: "/", icon: CreditCardIcon },
        { id: 2, title: "Debit Card", src: "/debit", icon: DebitCardIcon },
        { id: 3, title: "UPI", src: "/upi", icon: UpiIcon },
        { id: 4, title: "Wallet", src: "/wallet", icon: WalletIcon },
        { id: 5, title: "Net Banking", src: "/netbanking", icon: NetBankIcon },
        { id: 6, title: "EMI", src: "/emi", icon: EmiIcon },
        { id: 7, title: "BNPL", src: "/bnpl", icon: BnplIcon },
    ];
    const [select, setSelect] = useState(Menus[0])
    return (
        <div className="flex w-[20%] justify-end ">
            <div className="bg-white">
                <ul className="">
                    {Menus.map((Menu, index) => (
                        <li
                            key={index}
                            onClick={() => setSelect(Menu)}
                            className={`${Menu.id === select.id && 'bg-orange-50 font-medium text-orange-700'} w-[178px] h-[40px] left-[50px] md:py-6  font-medium text-sm   hover:bg-orange-50  m-1 flex items-center px-2 rounded-xl `}
                        // className=' w-[158px] h-[40px] left-[50px] py-8 font-semibold text-lg hover:bg-orange-200 flex items-center px-2 rounded-xl '
                        >
                            <Link to={Menu.src} className='w-full'>
                                <span className='px-1 flex items-center'>
                                    <img src={Menu.icon} /> {Menu.title}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="w-[1px] h-[381px] bg-gray-300">
              
            </div>
        </div>
    );
};
export default App;
