import React from 'react'
import poweredby from '../../../assets/Frame 32.svg'
function Footer() {
  return (
    <div className='box-content h-8 w-100 p-4  grid  place-items-center' >
        <img src={poweredby} alt="Powered By" />
    </div>
  )
}

export default Footer