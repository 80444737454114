import { useWindowSize } from '@react-hook/window-size'
import React, {useState} from 'react'
import DebitCardDetails from '../../components/DebitCard/DebitCardDetails'
import DebitPaymentCard from '../../components/DebitCard/DebitPaymentCard'
// import Header from '../../components/Header'

function DebitCard() {
  const [debitData, setDebitData] = useState({
    cardHolder : 'Card Holder Name',
    cardNumber : '0000 0000 0000 0000',
    expiry : 'MM/YY'
  })
  const [width, height] = useWindowSize()
  return (
    <div className='flex w-[80%] justify-evenly' style={{
      height : height - ( height * 0.1)
    }}>
      <DebitCardDetails setDebitData={setDebitData}/>
      <DebitPaymentCard debitvalues = {debitData}/>
    </div>
  )
}

export default DebitCard