import React from 'react'
import { Routes, Route } from 'react-router-dom'
import CreditCard from './pages/CreditCard/CreditCard'
import DebitCard from './pages/DebitCard/DebitCard'
import Home from './pages/Home/Home'
import NetBanking from './pages/NetBanking/NetBanking'
import Upi from './pages/Upi/Upi'
import Wallet from './pages/Wallet/Wallet'
import ThankYou from './pages/ThankYou/ThankYou'
import Oops from './pages/Oops/Oops'
import Waiting from './pages/Waiting/Waiting'
import NoInternet from './pages/NoInternet/NoInternet'
import NotFound from './pages/NotFound/NotFound'
import BadGateway from './pages/BadGateway/BadGateway'
import BankNameError from './components/BankNameError/BankNameError'
import Emi from './pages/EMI/Emi'
import BNPL from './pages/BNPL/BNPL'
function MobApp() {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route path='/credit' element={<CreditCard/>} />
      <Route path='/debit' element={<DebitCard/>} />
      <Route path='/upi' element={<Upi/> } />
      <Route path='/wallet' element={<Wallet/>}/>
      <Route path='/netbanking' element={<NetBanking/>} />
      <Route path='/emi' element={<Emi/>} />
      <Route path='/bnpl' element={<BNPL/>} />
      <Route path='/success' element={<ThankYou/>}/>
      <Route path='/rejected' element={<Oops/> }/>
      <Route path='/waiting' element={<Waiting/> }/>
      <Route path='/nointernet' element={<NoInternet/> }/>
      <Route path='/pagenotfound' element={<NotFound/> }/>
      <Route path='/badgateway' element={<BadGateway/> }/>
      <Route path='/banknameerror' element={<BankNameError/> }/>
    </Routes>
  )
}

export default MobApp