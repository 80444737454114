import React, { useEffect, useState } from 'react'
import axisbank from '../../assets/Frame 48.svg'
import icicibank from '../../assets/Frame 49.svg'
import sbibank from '../../assets/Frame 52.svg'
import canarabank from '../../assets/Frame 50.svg'
import southindianbank from '../../assets/Frame 51.svg'
import hdfcbank from '../../assets/Frame 53.svg'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ShieldCheck from '../../assets/ShieldCheck.svg'
import mastercard from '../../assets/Frame 36.svg'
import rupay from '../../assets/Frame 38.svg'
import npci from '../../assets/Frame 39.svg'
import pov from '../../assets/Frame 37.svg'
import visa from '../../assets/Frame35.svg'
import logo from '../../assets/Frame 32.svg'

function NetBankingDetails({setBankName}) {
  const apps = [
    { id: 1, src: axisbank, },
    { id: 2, src: icicibank },
    { id: 3, src: sbibank },
    { id: 4, src: canarabank },
    { id: 5, src: southindianbank },
    { id: 6, src: hdfcbank },]

  const [bank, setBank] = useState('Bank Name')
  const handleChange = (event) => {
    setBank(event.target.value);
  };
  useEffect(()=>{
    setBankName(bank)
  },[bank])
  return (
    <div className='flex-col  mt-1 2xl:space-y-18 space-y-4 w-[50%] 2xl:w-[45%]'>
      <div className='grid grid-cols-3 w-full'>
        {apps.map(app => (
          <div className='h-[40px] w-[120px] border border-gray-100 mx-5 my-5 flex items-center justify-center'>
            <img src={app.src} />
          </div>
        ))}
      </div>
      <div className='flex-col space-y-4'>
        <div className='flex-col space-y-2 '>
          <h3 className=' w-[200px] h-[20px]  font-semibold text-base text-black'>Select Bank</h3>
          <p className=' text-gray-400 w-[280px] h-[16px] font-semibold text-sm'>Find your bank from the list below</p>
        </div>
        <div className='w-full '>
          <Select
            className='w-full border-gray-500 hover:bg-yellow-50 rounded-md h-[47px] focus:oultine-none '
            labelId=""
            id=""
            value={bank}
            displayEmpty
            onChange={handleChange}
          >
            <MenuItem value="Bank Name" className='text-gray-400 font-normal text-sm '>Select your bank</MenuItem>
            <MenuItem value={'Axis Bank'}>Axis Bank</MenuItem>
            <MenuItem value={'Icici Bank'}>Icici Bank</MenuItem>
            <MenuItem value={'SBI Bank'}>SBI Bank</MenuItem>
            <MenuItem value={'Canara Bank'}>Canara Bank</MenuItem>
            <MenuItem value={'South Indian Bank'}>South Indian Bank</MenuItem>
            <MenuItem value={'HDFC Bank'}>HDFC Bank</MenuItem>
          </Select>
        </div>
        <div className='w-full flex justify-between pt-4 space-x-4 ml-4'>
          <div className='w-[120px] h-[17px] flex justify-between space-x-2'>
            <img src={visa}
              alt="visa"
            />
            <img src={pov}
              alt="visa"
              className='h-[25px]'
            />
            <img src={mastercard}
              alt="master"
            />
            <img src={rupay}
              alt="pay"
            />

          </div>
          <div className=' w-[182px] h-[17px] flex items-center'>
            <img src={ShieldCheck} />
            <p className='text-gray-400 text-xs'>Safe and secure payments.</p>
          </div>
        </div>
        <div className='h-[1px] w-full  bg-gray-300' />
        <div className=' h-[14px] w-full flex justify-center'>
          <p className='text-gray-400 text-xs items-center'>2019-21 secure.pay10.com. All rights reserved</p>
        </div>
        <div className='flex justify-center pt-[30px]'>
          <button className='uppercase  w-[402px] h-[40.63px]  bg-[#F23322] rounded-lg text-white'>
            PAY NOW</button>
        </div>
        <div className=' w-full h-[17px]  flex justify-center items-center'>
          <div className=''>
            <img src={logo}
              alt='pay10 logo'
              className='w-[140px] h-[17px]' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NetBankingDetails