import React from 'react'
import comingsoon from '../../assets/Coming Soon.svg'
import dots from '../../assets/Group 67.svg'
import logo from '../../assets/Frame 32.svg'

function Bnpl() {
    return (
        <div className=' w-[80%] relative'>
            <div className='absolute top-[30%] left-[35%] bottom-[50%] flex-col justify-center items-center'>
                <img src={comingsoon} alt='coming soon' />
                <img src={dots} alt='dots' className='mt-10 ml-[115px]' />
            </div>
            <div className='absolute left-[44%] top-[60%]'>
                <img src={logo} alt='logo' />
            </div>
        </div>
    )
}

export default Bnpl