import React from 'react'
import './PaymentCard.css'
import qrcode from '../../assets/Rectangle 200.svg'
import upi from '../../assets/Frame 54.svg'
import npci from '../../assets/Frame 39.svg'
function UpiPaymentCard(props) {
    const {upivalue} = props
    const items = [{ name: 'Item Total', value: '₹1650' },
    { name: 'Product', value: '3 Items' },
    { name: 'Order Number', value: '123456' },
    { name: 'GST', value: '₹25.00' },
    { name: 'SGST', value: '₹0.500' },
    { name: 'Shipping & Handling', value: '₹15' }]
    return (
        <div className='flex flex-col justify-around w-[30%] cursor-pointer rounded-3xl bg-orange-50 transition duration-300 ease-in-out mt-[-10px] px-8 relative'>
            <div className='w-[237px] h-[259px] relative mx-auto top-[-41px] bg-gray-100 rounded-2xl paymentcard bg-no-repeat'>
                <div className='w-[70px] h-[20px] bg-red-600 absolute left-20 top-[-10px] -z-10'></div>
                <div className='absolute w-[45px] h-[16px] top-[15px] left-[20px]'>
                    <img src={upi} alt='upi' className='object-contain' />
                </div>
                <div className='absolute w-[109px] h-[109px] left-[65px] top-[41px]'>
                    <img src={qrcode} alt='qrcode' />
                </div>
                {/* <div className='w-[99px]'>
                    <p className='font-semibold text-lg absolute top-[160px] left-[13px]'>Monty Norman</p>
                </div> */}
                <div className=''>
                    <p className='font-semibold text-lg absolute top-[170px] left-[15px] w-[164px] mt-6'>{upivalue}</p>
                </div>
                <div className='absolute flex justify-between  top-[244px] left-[15px]'>
                    {/* <div>
                        <p className='font-semibold text-lg'>09/26</p>
                    </div> */}
                    <div className='h-[18px] w-[47px] absolute left-[140px] top-[-8px]'>
                        <img src={npci} alt='npci'
                            className='object-contain' />
                    </div>
                </div>
            </div>
            <div className='flow-root mt-[-10px]'>
                {items.map(item => (
                    <ul className='divide-y divide-gray-200 dark:divide-gray-700'>
                        <li className='py-2 2xl:py-4'>
                            <div className='flex justify-between'>
                                <p className='w-[150px] h-[15px] text-gray-400 font-medium text-sm'>{item.name}</p>
                                <p className='font-semibold  h-[17px] text-sm'>{item.value}</p>
                            </div>
                        </li>
                        <div className='h-[0.5px] bg-gray-300 ' />
                    </ul>
                ))}
            </div>
            <div className='h-[50px] bg-[#f4c456] flex items-center justify-center rounded-md  gap-24 relative bottom-[0px]'>
                <div>
                    <h3 className='font-semibold text-white text-xl'>Total</h3>
                </div>
                <div>
                    <h3 className='font-semibold text-white text-xl'>₹1650.95</h3>
                </div>
            </div>
        </div>
    )
}

export default UpiPaymentCard