import React from 'react'
import paytm from '../../assets/Frame 55.svg'
import phonepay from '../../assets/Frame 58.svg'
import ShieldCheck from '../../assets/ShieldCheck.svg'
import mastercard from '../../assets/Frame 36.svg'
import rupay from '../../assets/Frame 38.svg'
import npci from '../../assets/Frame 39.svg'
import pov from '../../assets/Frame 37.svg'
import logo from '../../assets/Frame 32.svg'
import visa from '../../assets/Frame35.svg'
function WalletDetails() {
    const apps = [
        { id: 1, src: paytm },
        { id: 2, src: phonepay },
    ]
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const formatNumber = e => {
        const inputVal = e.target.value.replace(/ /g, "");
        let inputNumbersOnly = inputVal.replace(/\D/g, "");

        if (inputNumbersOnly.length > 10) {
            inputNumbersOnly = inputNumbersOnly.substr(0, 10);
        }

        const splits = inputNumbersOnly.match(/.{1,1}/g);

        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" ");
        }
        setPhoneNumber(spacedNumber);
    };

    return (
        <div className='flex-col  mt-1 2xl:space-y-8 space-y-4 w-[50%] 2xl:w-[45%]'>
            <div className='grid grid-cols-2 w-full'>
                {apps.map(app => (
                    <div className='h-[50px] w-[130px] border border-gray-200 mx-5 my-5 flex items-center justify-center'>
                        <img src={app.src} />
                    </div>
                ))}
            </div>
            <div className='flex-col space-y-6'>
                <div className='flex-col space-y-2'>
                    <h3 className='w-[200px] h-[20px]  font-semibold text-base text-black'>Phone Number</h3>
                    <p className=' text-gray-400 w-[280px] h-[16px] font-semibold text-sm'>Enter your phone number here</p>
                </div>
                <div className=' w-full  '>
                    <input className="border border-gray-500 rounded-md inline-block py-2 px-3 w-full text-gray-600 text-center  hover:bg-yellow-50"
                        type="tel"
                        placeholder="0 0 0 0 0 0 0 0 0 0"
                        value={phoneNumber}
                        onChange={formatNumber} />
                </div>
                <div className=' w-[500px] h-[40px] '>
                    <p className='text-gray-400 text-xs font-normal'>
                        Mobile number is a unique identifier that can be linked to a persons
                        wallet number to make payments
                    </p>
                </div>
                <div className='flex justify-between pt-4 space-x-4 '>
                    <div className='w-[120px] h-[17px] flex justify-start space-x-2'>
                        <img src={visa}
                            alt="visa"
                        />
                        <img src={mastercard}
                            alt="master"
                        />
                        <img src={pov}
                            alt="pov"
                            className='h-[25px]'
                        />
                        <img src={npci}
                            alt="rupay"
                        />
                        <img src={rupay}
                            alt="pay"
                        />
                    </div>
                    <div className=' w-full h-[17px] flex items-center justify-end'>
                        <img src={ShieldCheck} />
                        <p className='text-gray-400 text-xs'>Safe and secure payments.</p>
                    </div>
                </div>
                <div className='h-[1px] w-full  bg-gray-300' />
                <div className=' h-[14px]  flex justify-center'>
                    <p className='text-gray-400 text-xs'>2019-21 secure.pay10.com. All rights reserved</p>
                </div>
                <div className='flex justify-center '>
                    <button className='uppercase  w-[402px] h-[40.63px]  bg-[#F23322]  rounded-lg text-white'>
                        PAY NOW</button>
                </div>
                <div className=' w-full h-[17px]  flex justify-center items-center'>
                    <div className=''>
                        <img src={logo}
                            alt='pay10 logo'
                            className='w-[140px] h-[17px]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletDetails