import { useWindowSize } from '@react-hook/window-size'
import React from 'react'
import Avatar from '@mui/material/Avatar';
import poweredby from '../../../assets/poweredby.png'
import frame from '../../../assets/Frame47.png'

function BadGateway() {
    const [width, height] = useWindowSize()
    return (
      <div className='relative w-full'>
        <div className='w-full' style={{
          height: height * 0.1,
          background:'linear-gradient(90deg, #F23322 0%, #F2A71B 100%)',
        }}>
          <div className="flex h-full p-5 items-center">
            <div className="">
            <Avatar src='https://s3-alpha-sig.figma.com/img/cb0d/8aeb/7eb868ee899dbbc31d54eef8983bf26a?Expires=1651449600&Signature=hNSQ9jgcnSMgt6hMcIlM-2dQtYIs~yoPqwhA583kZ2LERnd2BFEv26ECZaCqUStdRhsH-RWlfoQTz~N9ZGUVNr6rD~5tJF7Wtnx~krp6UgHsndZZXKuxhlkZQ-KAayaFony98MbbQDF1MwxqozQmxMYc~Sg22Ai76RsOB-MwAlNdY-geQCawrQpq15qgc9AxYA3UfZJlssTUPohUZ~tP7BhwH3TABzSI7kBC921UN46lIcHfgLjkiSK2TLMEvbOjXoqe9FoGZZDbQgVJRNBl4Nlz1SjdZDgaJKtRwBaYkOJU6TVAl1sZx4tCqU5pqLhHZt1L~2myz37GMBNebQo2cw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA'
              alt='logo'
              sx={{
                  Width :'35.56px',
                  Height: '35.56px',
                  boxShadow: '0px 6px 13px -3px rgb(108 107 108);'
              }} />
            </div>
            <div
                style={{
                    fontFamily: 'Jost',
                    fontSize: '24px',
                    fontWeight: 600,
                    lineHeight: '35px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: '#FFFFFF',
                    padding: '0px 18px'
                }}
              >
                <span>Miriyam Saree Store</span>
              </div>
          </div>
        </div>
        <div className='p-5 grid content-center' style={{
          height: height - (height * 0.1 + height * 0.1)
        }}>
          <div className="mb-5 p-5 flex flex-col items-center justify-center" style={{
            background: 'rgba(242, 51, 34, 0.1)',
            borderRadius: '14px',
            height: '444px'
          }}>
            <img style={{
              width: '356px',
              height: '149px',
            }} src={frame} alt="" />
            
            <p style={{
              fontFamily: 'Do Hyeon',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '22px',
              lineHeight: '28px',
              textAlign: 'center',
              color:'#444445',
              marginTop:'40px'
            }}>Bad Gateway Error</p>
            
          </div>
        </div>
        <div className='grid  place-items-center' style={{
          height: height * 0.2,
          position: 'absolute',
          top: height - height * 0.2,
          width:width
        }}>
            <img src={poweredby} alt="Powered By" />
        </div>
      </div>
    )
}

export default BadGateway