import React from 'react'
import Bnpl from '../../components/BNPL/Bnpl'
import Footer from '../../layouts/Footer/Footer'
import Header from '../../layouts/Header/Header'

function BNPL() {
  return (
    <div>
        <Header/>
        <Bnpl/>
        <Footer/>
    </div>
  )
}

export default BNPL