import React from 'react'
import poweredby from '../../../assets/Frame 32.svg'

function HomeFooter() {
  return (
    <div className='box-content h-8 w-100 p-4  grid  place-items-center' style={{boxShadow: '0px -3px 15px -5px rgba(166,166,166,1)', marginTop:'50px'}}>
        <img src={poweredby} alt="Powered By" />
    </div>
  )
}

export default HomeFooter