import React from 'react'
import logo from '../assets/Mask Group.svg'
function Header() {
    return (
        <header className='w-[67%] 2xl:w-[62%]'>
            <div className='flex-col items-center  justify-center bg-white p-1  flex-grow py-3 ml-[83px] 2xl:ml-[154px]'>
                <div className='mt-1 items-center flex space-x-10 justify-between '>
                    <div className='flex items-center space-x-3 '>
                        <img src={logo}
                            alt='logo'
                            className='object-contain w-[40px] h-[42px]' />
                        <h1 className=' w-[220px] font-extrabold md:font-bold text-2xl md:text-xl text-transparent bg-clip-text bg-gradient-to-r from-orange-700 to-orange-300'>Miri Saree Store</h1>
                    </div>
                    <p className='text-sm font-semibold text-gray-400'>01:59min</p>
                </div>
                <div className='h-[2px] bg-gray-100 w-full mt-2' />
            </div>
        </header>
    )
}

export default Header