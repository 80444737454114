import React from 'react'
import { Box, Select, MenuItem, Divider } from '@mui/material'
import upi from '../../../assets/Icons Background (2).svg'
import upilogo from '../../../assets/image58.svg'
import paytm from '../../../assets/Frame 55.svg'
import gpay from '../../../assets/Frame 57.svg'
import amazonpay from '../../../assets/Frame 56.svg'
import phonepay from '../../../assets/Frame 58.svg'
import freecharge from '../../../assets/Frame 59.svg'
import rupay from '../../../assets/Frame 38.svg'
import mastercard from '../../../assets/Frame 36.svg'
import pci from '../../../assets/Frame 37.svg'
import npci from '../../../assets/Frame 39.svg'
import ShieldCheck from '../../../assets/ShieldCheck.svg'


function UpiComponent() {
  const [upis, setUpi] = React.useState(10);

  const handleChange = (event) => {
      setUpi(event.target.value);
  };
  const apps = [
    { id: 1, src: upilogo },
    { id: 2, src: paytm },
    { id: 3, src: gpay },
    { id: 4, src: amazonpay },
    { id: 5, src: phonepay },
    { id: 6, src: freecharge },
  ]
  return (
    <div className="creditcard-section px-4 pt-4" style={{fontFamily:'Jost'}}>
      <Box sx={{padding:'0px 5px'}}>
        <div className="flex items-center">
          <img src={upi} alt="" />
          <label className='font-semibold text-lg' htmlFor="credit" style={{color:'#252526'}}>UPI</label>
        </div>
      </Box>
      <Box>
      <div className='grid grid-cols-3 justify-items-center'>
          {apps.map(app => (
              <div key={app.id} className='h-[50px] w-[100px] border border-gray-200 my-5 flex items-center justify-center'>
                  <img src={app.src} alt=""/>
              </div>
          ))}
      </div>
      </Box>
      
      <Box sx={{marginBottom:'15px', padding:'0px 13px'}}>
        <div>
            <h3 className='mb-2 font-semibold text-base text-black'>UPI ID</h3>
            <p className='mb-5 text-gray-400 w-[280px] h-[16px] font-semibold text-sm'>Enter your UPI id here</p>
        </div>
        <div className='flex justify-between'>
          <input className="border h-[45px] border-gray-500 rounded-md inline-block py-3 px-3 w-1/2 mr-2 text-gray-600 tracking-widest hover:bg-yellow-50" type="text" placeholder="example" />
          <Select
              className='w-1/2  border-gray-500 hover:bg-yellow-50 h-[45px]'
              labelId=""
              id=""
              value={upis}
              label=""
              onChange={handleChange}
          >
              <MenuItem value={10}>@okupiid</MenuItem>
              <MenuItem value={20}>@okhdfc</MenuItem>
              <MenuItem value={30}>@okicici</MenuItem>
          </Select>
        </div>
        <div className='w-full mt-7'>
          <button className='w-full h-[42px]  bg-[#F23322]  rounded-md text-white'>
            Verify ID
          </button>
        </div>
        <div className='my-5'>
          <p className='text-gray-400 text-sm font-normal'>
              VPA is a unique payment address that can be linked to a persons
              bank account to make payments
          </p>
        </div>
        <Divider/>
        <Box sx={{margin:'15px 0'}}>
        <div className="flex flex-row w-full">
          <div className='flex flex-row justify-between items-center w-1/2'>
            <div className=''>
              <img src={npci} alt="npci" style={{
                width: '37.33px',
                height: '16px'
              }} />
            </div>
            <div className=''>
              <img src={mastercard} alt="" style={{
                width: '46.08px',
                height: '16px'
              }} />
            </div>
            <div className=''>
              <img src={pci} alt="pci" style={{
                width: '30.86px',
                height: '16px'
              }} />
            </div>
            <div className=''>
              <img src={rupay} alt="rupay" style={{
                width: '41px',
                height: '16px'
              }} />
            </div>
          </div>
          <div className='flex flex-row justify-end items-center w-1/2'>
            <div className=''>
              <img src={ShieldCheck} alt="" />
            </div>
            <div className=' pl-2'>
              <label htmlFor="" style={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '17px',
                color:'#888888'
              }}>Safe and secure payments.</label>
            </div>
          </div>
        </div>
      </Box>
      <Divider/>

      <Box>
        <div className='grid place-items-center mt-10'>
          <label htmlFor="" style={{
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '14px',
            color:'#888888'
          }}>2019-21 secure.pay10.com. All rights reserved</label>
        </div>
      </Box>

      <Box sx={{
        marginTop:'150px',
      }}>
        <button style={{
          fontFamily:'Jost',
          color:'#ffffff',
          width:'100%',
          background: '#F23322',
          borderRadius: '10px',
          fontWeight: 700,
          fontSize: '18.3215px',
          lineHeight: '26px',
          height:'48px'
        }}>PAY NOW</button>
      </Box>
        
      </Box>

    </div>
  )
}

export default UpiComponent