import React from 'react'
import ApprovalImage from '../../assets/Frame42.png'
import logo from '../../assets/Mask Group.svg'
import footerLogo from '../../assets/Frame 32.svg'
function PaymentApproval() {
    return (
        <div className='flex-col p-10'>
            <div className='w-[850px] h-[300px]  bg-red-100 mx-auto rounded-lg '>
                <div class="px-40 py-20 bg-red-100 rounded-md ">
                    <div class="flex flex-col items-center">
                        {/* <h1 class="font-bold text-red-400 text-9xl">404</h1> */}
                        <img src={ApprovalImage} alt='approved' />

                        <p class="mb-8 text-center text-gray-500 md:text-lg">
                            Thank you, your payment has been successful.
                        </p>
                    </div>
                </div>
            </div>
            <div className='h-[130px] w-[850px] border-green-700 border-2 bg-white relative mt-[60px]  mx-auto rounded-xl'>
                <div className='flex-col'>
                    <div className='flex justify-between absolute font-normal top-[30px] w-full'>
                        <div className='font-semibold flex justify-between space-x-10 ml-1'>
                            <p className='text-gray-700 '>Transaction Date :</p>
                            <p>01/01/2022</p>
                        </div>
                        <div className='font-bold flex space-x-10 mr-1 '>
                            <p>Total Amount :</p>
                            <p className=''>₹1695.50</p>
                        </div>
                    </div>
                    <div className='relative font-normal  top-[80px] ml-1  w-full '>
                        <div className='font-semibold  flex space-x-11'>
                            <p className='text-gray-700'>Time :</p>
                            <p className='pl-20 '>13:05</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' w-full h-[20px]  flex justify-center items-center mt-4'>
                <div className=''>
                    <img src={footerLogo}
                        alt='pay10 logo'
                        className='w-[150px] h-[20px]' />
                </div>
            </div>

        </div>

    )
}

export default PaymentApproval