import React from 'react'
import { Box, Divider } from '@mui/material'
import walleticon from '../../../assets/Icons Background (3).svg'
import paytm from '../../../assets/Frame 55.svg'
import phonepay from '../../../assets/Frame 58.svg'
import visa from '../../../assets/Frame35.svg'
import mastercard from '../../../assets/Frame 36.svg'
import pci from '../../../assets/Frame 39.svg'
import npci from '../../../assets/Frame 37.svg'
import ShieldCheck from '../../../assets/ShieldCheck.svg'

function WalletComponent() {
    const apps = [
        { id: 1, src: paytm },
        { id: 2, src: phonepay },
    ]
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const formatNumber = e => {
        const inputVal = e.target.value.replace(/ /g, "");
        let inputNumbersOnly = inputVal.replace(/\D/g, "");

        if (inputNumbersOnly.length > 10) {
            inputNumbersOnly = inputNumbersOnly.substr(0, 10);
        }

        const splits = inputNumbersOnly.match(/.{1,1}/g);

        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" ");
        }
        setPhoneNumber(spacedNumber);
    };
    return (
        <div className="creditcard-section px-4 pt-4" style={{fontFamily:'Jost',}}>
            <Box sx={{padding:'0px 15px'}}>
                <div className="flex items-center">
                <img src={walleticon} alt="" />
                <label className='font-semibold text-lg' htmlFor="credit" style={{color:'#252526'}}>Wallet</label>
                </div>
            </Box>
            <Box>
            <div className='grid grid-cols-3 justify-items-center'>
                {apps.map(app => (
                    <div key={app.id} className='h-[50px] w-[100px] border border-gray-200 my-5 flex items-center justify-center'>
                        <img src={app.src} alt=""/>
                    </div>
                ))}
            </div>
            </Box>
            
            <Box sx={{marginBottom:'15px', padding:'0px 13px'}}>
                <div>
                    <h3 className='mb-2 font-semibold text-base text-black'>Phone Number</h3>
                    <p className='mb-5 text-gray-400 w-[280px] h-[16px] font-semibold text-sm'>Enter your phone number here</p>
                </div>
                <div className='w-full'>
                    <input className="border w-full h-[45px] border-gray-500 rounded-md inline-block py-3 px-3 mr-2 text-gray-600 tracking-widest hover:bg-yellow-50" 
                    type="text" 
                    placeholder="0 0 0 0 0 0 0 0 0 0"
                    value={phoneNumber}
                    onChange={formatNumber} />
                </div>
                <div className='my-5'>
                    <p className='text-gray-400 text-sm font-normal'>
                    Mobile number is a unique identifier that can be linked to a 
                    persons wallet number to make payments
                    </p>
                </div>
                <Divider/>
                <Box sx={{margin:'15px 0'}}>
                <div className="flex flex-row w-full">
                <div className='flex flex-row justify-between items-center w-1/2'>
                    <div className=''>
                    <img src={visa} alt="visa" style={{
                        width: '37.33px',
                        height: '16px'
                    }} />
                    </div>
                    <div className=''>
                    <img src={mastercard} alt="" style={{
                        width: '46.08px',
                        height: '16px'
                    }} />
                    </div>
                    <div className=''>
                    <img src={npci} alt="npci" style={{
                        width: '41px',
                        height: '16px'
                    }} />
                    </div>
                    <div className=''>
                    <img src={pci} alt="pci" style={{
                        width: '30.86px',
                        height: '16px'
                    }} />
                    </div>
                </div>
                <div className='flex flex-row justify-end items-center w-1/2'>
                    <div className=''>
                    <img src={ShieldCheck} alt="" />
                    </div>
                    <div className=' pl-2'>
                    <label htmlFor="" style={{
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '17px',
                        color:'#888888'
                    }}>Safe and secure payments.</label>
                    </div>
                </div>
                </div>
            </Box>
            <Divider/>
        
            <Box>
                <div className='grid place-items-center mt-10'>
                <label htmlFor="" style={{
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '14px',
                    color:'#888888'
                }}>2019-21 secure.pay10.com. All rights reserved</label>
                </div>
            </Box>
        
            <Box sx={{
                marginTop:'250px',
            }}>
                <button style={{
                    fontFamily:'Jost',
                    color:'#ffffff',
                    width:'100%',
                    background: '#F23322',
                    borderRadius: '10px',
                    fontWeight: 700,
                    fontSize: '18.3215px',
                    lineHeight: '26px',
                    height:'48px'
                }}>PAY NOW</button>
            </Box>
                
            </Box>
        </div>
    )
}

export default WalletComponent