import React, { useState } from 'react'
import UpiApps from '../../components/UPI/UpiApps'
import UpiPaymentCard from '../../components/UPI/UpiPaymentCard'
function Upi() {
  const [upiValue,setUpiValue] = useState('')
  return (
    <div className='flex w-[80%] justify-evenly'>
      <UpiApps setUpiValue={setUpiValue}/>
      <UpiPaymentCard upivalue={upiValue}/>
    </div>
  )
}

export default Upi