import React from 'react'
import { Box } from '@mui/material'
import bnpl from '../../../assets/Bnpl icon.svg'
import ComingSoon from '../../pages/ComingSoon/ComingSoon'

function Bnpl() {
  return (
    <div className="bnpl-section p-4" style={{fontFamily:'Jost'}}>
      <Box sx={{padding:'15px 5px'}}>
        <div className="flex items-center">
          <img src={bnpl} alt="" />
          <label className='text-lg font-semibold' htmlFor="credit" style={{color:'#252526'}}>Buy Now Pay Later (BNPL)</label>
        </div>
      </Box>
      <ComingSoon/>
    </div>
  )
}

export default Bnpl