import React, { useEffect, useState } from 'react'
import ShieldCheck from '../../assets/ShieldCheck.svg'
import mastercard from '../../assets/Frame 36.svg'
import rupay from '../../assets/Frame 38.svg'
import pov from '../../assets/Frame 37.svg'
import visa from '../../assets/Frame35.svg'
import logo from '../../assets/Frame 32.svg'
function CardDetails({setDebitData}) {
  const [cardNumber, setCardNumber] = useState("");
  const [cvvNumber, setCvvNumber] = useState("")
  const [month, setMonth] = useState("MM")
  const [year, setYear] = useState("YY")
  const [name, setName] = useState("")

  const formatAndSetCcNumber = e => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 19) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 19);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  ");
    }
    spacedNumber !== '' ? setCardNumber(spacedNumber) : setCardNumber('');
  };
  const formatAndSetCcNumber2 = (e)=>{
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, ""); 

    if (inputNumbersOnly.length === 17 || inputNumbersOnly.length === 18) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  "); 
    }
    spacedNumber !== '' ? setCardNumber(spacedNumber) : setCardNumber('');
  }

  const formatCvvNumber = e => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 3) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 3);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  ");
    }
    
    spacedNumber !== '000' ? setCvvNumber(spacedNumber) : setCvvNumber('')
  }
  const formatMonth = e => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 2) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 2);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  ");
    }
    const d = new Date();
    let mnth = d.getMonth() + 1
    let twodigityear = d.getFullYear().toString().substr(-2)
    if(twodigityear === year){
      if(spacedNumber.length === 2){
        if(parseInt(spacedNumber) < mnth ){
          setMonth('MM')
          return false
        }
      }else if(parseInt(spacedNumber) !== 1 && parseInt(spacedNumber) !== 0 ){
        if(parseInt(spacedNumber) < mnth ){
          setMonth('MM')
          return false
        }
      }

      if(spacedNumber <= 12){
        spacedNumber !== '' ? setMonth(spacedNumber) : setMonth('MM')
      }else{
        setMonth('MM')
      }
    }else{
      if(spacedNumber <= 12){
        spacedNumber !== '' ? setMonth(spacedNumber) : setMonth('MM')
      }else{
        setMonth('MM')
      }
    }
  }
  const formatYear = e => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 2) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 2);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  ");
    }
    const d = new Date();
    if(spacedNumber.length === 2){
      let year = d.getFullYear().toString().substr(-2)
      if(parseInt(year) > spacedNumber){
        spacedNumber =''
      }
      else{
        let mnth = d.getMonth() + 1
        if(month < mnth){
          setMonth('MM')
        }
      }
    }
    spacedNumber !== ''? setYear(spacedNumber) : setYear('YY')
  }
  const setname = (e)=>{
    if(e.target.value !== ''){
      let inputNumbersOnly = e.target.value.replace(/[!\@\^\_\&\/\\#,\|+()$~%.'":*?<>{}\d]/g, '');
      let inputVal = inputNumbersOnly; 
      if (inputVal.length > 50) {
        inputVal = inputVal.substr(0, 50);
      }

      setName(inputVal)
      if(name !== ''){
        setDebitData({
          cardHolder : inputVal,
          cardNumber : cardNumber,
          expiry : `${month}/${year}`
        })
      }
    }else{
      setName('')
    }
  }
  useEffect(()=>{
    if(cardNumber !==''){
      setDebitData({
        cardHolder : name,
        cardNumber : cardNumber,
        expiry : `${month}/${year}`
      })
    }else{
      setDebitData({
        cardHolder : name,
        cardNumber : '0000 0000 0000 0000',
        expiry : `${month}/${year}`
      })
    }
  },[name,cardNumber,month,year])
  return (
    <>
      <div className='flex flex-wrap justify-around flex-col w-[50%] 2xl:w-[45%]'>
        <div className='flex-col space-y-2 '>
          <div className='w-[280px] h-[20px] flex items-center'>
            <h3 className='font-medium w-full text-base text-black'>Card Number</h3>
          </div>
          <div className='w-[400px] h-[20px] flex items-center'>
            <p className=' text-gray-400 w-full text-sm'>Enter the 16-digit card number, on your card</p>
          </div>
          <div className=' w-full h-[36px] flex items-center '>
            <input className="border-[1px] border-gray-500 rounded-md inline-block py-2 px-3 w-full text-gray-600 tracking-widest hover:bg-yellow-100 text-center"
              type="text"
              placeholder="0000 0000 0000 0000"
              value={cardNumber}
              onChange={formatAndSetCcNumber}
              onBlur = {formatAndSetCcNumber2}
            />
          </div>
        </div>
        {/* CVV */}
        <div className='flex space-x-2 pb-5 items-center'>
          <div className='flex-col space-y-2'>
            <h3 className=' w-[33px] h-[20px]  font-medium text-base text-black'>CVV</h3>
            <p className='   text-gray-400 w-[305px] h-[16px] text-sm'>
              Enter 3 digit number on your card( front side for amex, back for others). Security code for Amex.
            </p>
          </div>
          <div className=' w-full h-[36px]  '>
            <input
              type="password"
              className="border border-gray-500 rounded-md inline-block text-center py-2 px-3 w-full text-gray-600  hover:bg-yellow-100"
              placeholder='000'
              value={cvvNumber}
              onChange={formatCvvNumber} />
          </div>
        </div>
        {/* EXPIRY */}
        <div className='flex items-center relative justify-between'>
          <div className='flex-col space-y-2 '>
            <h3 className=' w-[50px]   font-medium text-base text-black'>Expiry</h3>
            <p className=' text-gray-400 w-[254px]  text-sm mt-1'>
              Enter the expiry date on the card
            </p>
          </div>
          <div className=' w-[250px] h-[36px] flex space-x-1'>
            <input type="text"
              className="border border-gray-500 rounded-md inline-block text-center py-2 px-3 w-full text-gray-600 tracking-widest items-center hover:bg-yellow-100"
              placeholder='MM'
              value={month}
              onChange={formatMonth} />
            <input
              type="tel"
              className="border border-gray-500 rounded-md inline-block text-center py-2 px-3 w-full text-gray-600 tracking-widest hover:bg-yellow-100"
              placeholder='YY'
              value={year}
              onChange={formatYear} />
          </div>
        </div>
        {/* Card holder name */}
        <div className='flex-col space-y-2'>
          <h3 className=' font-semibold text-base text-black'>Card Holder Name</h3>
          <p className='  text-gray-400 w-[250px] h-[15px] text-sm'>Enter name on the card</p>
          <div className='  h-[36px] '>
            <input onChange={setname} value={name} maxLength='50' className="border border-gray-500 mt-2 rounded-md inline-block py-2 px-3 w-full text-gray-600 tracking-widest uppercase hover:bg-yellow-100" type="text" placeholder="Card holder name" />
          </div>
        </div>
        {/* verified cards */}
        <div className='flex justify-between pt-6 space-x-4'>
          <div className=' w-[120px] h-[17px] flex justify-between space-x-2 '>
            <img src={visa}
              alt="visa"
            />
            <img src={mastercard}
              alt="master"
            />
            <img src={pov}
              alt="npci"
              className='h-[25px] '
            />
            <img src={rupay}
              alt="pay"
            />
          </div>

          <div className=' w-[182px] h-[17px] flex items-center'>
            <img src={ShieldCheck} alt=''/>
            <p className='text-gray-400 text-xs'>Safe and secure payments.</p>
          </div>
        </div>
        <div className='h-[1px] w-full ml-3  bg-gray-300 ' />
        {/* rights */}
        <div className=' h-[14px] w-full  items-center'>
          <p className='text-gray-400 text-xs text-center'>2019-21 secure.pay10.com. All rights reserved</p>
        </div>

        {/* Buttons */}
        <div className='flex justify-center'>
          <button className='uppercase  w-[402px] h-[40.63px]  bg-[#F23322] rounded-lg text-white'>
            PAY NOW</button>
        </div>
        {/* Powered by */}

        <div className=' w-full h-[17px]  flex justify-center items-center'>
          <div className=''>
            <img src={logo}
              alt='pay10 logo'
              className='w-[140px] h-[17px]' />
          </div>
        </div>

      </div>
    </>
    
  )
}

export default CardDetails