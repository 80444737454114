import React from 'react'
import NoNetWork from '../../assets/Frame43.png'
function NoInternet() {
    return (
        <div className='flex-col px-20 py-10'>
            <div className='w-[700px] h-[450px]  bg-red-100 rounded-lg'>
                <div class="px-40 py-20 bg-red-100 rounded-md ">
                    <div class="flex flex-col items-center ">
                        {/* <h1 class="font-bold text-red-400 text-9xl">404</h1> */}
                        <img src={NoNetWork} alt='wifi' />
                        <h6 class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl uppercase">
                            No Internet
                        </h6>

                        <p class="mb-8 text-center  text-gray-600 font-semibold md:text-lg">
                            Sorry for the trouble caused
                        </p>
                        <p class="mb-10 text-gray-600 font-semibold text-sm text-center">
                            Your device has no active internet connection at the moment.
                            Please ensure the connection and try again.
                        </p>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoInternet