import React, {useState} from 'react'
import NetBankingPaymentCard from '../../components/NetBanking/NetBankingPaymentCard'
import NetBankingDetails from '../../components/NetBanking/NetBankingDetails'
function NetBanking() {

  const [bankName, setBankName] = useState('Bank Name')
  return (
    <div className='flex w-[80%] justify-evenly'>
      <NetBankingDetails setBankName={setBankName}/>
      <NetBankingPaymentCard bankname = {bankName} />
    </div>
  )
}

export default NetBanking