import React from 'react'
import RejectedImage from '../../assets/Frame44.png'
import logo from '../../assets/Mask Group.svg'
function PaymentRejected() {
    return (
        <div className='flex-col p-10 web-payten'>            
            <div className='  w-[800px] h-[350px]  bg-red-100  mx-auto  rounded-lg'>
                <div class="px-40 py-20 bg-red-100 rounded-md ">
                    <div class="flex flex-col items-center">
                        {/* <h1 class="font-bold text-red-400 text-9xl">404</h1> */}
                        <img src={RejectedImage} alt='waiting payment' />

                        <h1 class="mb-8 text-center text-gray-700 md:text-lg font-bold">
                            Payment rejected.
                        </h1>
                        <p class="mb-10 text-center text-gray-500 md:text-lg">
                            If the amount debited for the transaction, will be credited back to your card / account / wallet / UPI
                            within stipulated time.
                            We are sorry for the trouble caused!
                        </p>
                        <div>
                            <button className='w-[452px] h-[58px] rounded-lg text-white bg-red-700'>Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PaymentRejected