import React from 'react'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import './HomeHeader.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import logo from '../../../assets/pay10logo.png'
function HomeHeader() {
  return (
    <Box 
        sx={{
            background:'linear-gradient(90deg, #F23322 0%, #F2A71B 100%)',
            width: '100%',
            height: '360px',
            left: '0px',
            top: '0px',
            paddingTop : '23px'
        }}
    >
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            p: '0px 24px',
            m: '0px',
            justifyContent: 'space-between',
            }}
        >
            <div>
                <Avatar src={logo}
                        alt='logo'
                        sx={{
                            Width :'35.56px',
                            Height: '35.56px',
                            boxShadow: '0px 6px 13px -3px rgb(108 107 108);'
                        }} />
            </div>
            <div
                style={{
                    fontFamily: 'Jost',
                    fontSize: '24px',
                    fontWeight: 600,
                    lineHeight: '35px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: '#FFFFFF',
                    padding: '0px 7px'
                }}
            >
                <span>Miriyam Saree Store</span>
            </div>
            <div
            style={{
                fontFamily: 'Jost',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#FFFFFF',
                textAlign:'end',
                paddingTop : '5px'
            }}
            >
                <span>01:59 min</span>
            </div>
        </Box>
        <div style={{
            margin:'12px 24px',
            height:'0px',
            border: '0.458036px solid rgb(218 218 218 / 65%)'
        }} />
        <Box
            sx={{
                margin:'0px 24px',
            }}
        >
            <div className="priceWrap">
                <div className="listgroup">
                    <div className="listlabel">
                        Total Amount
                    </div>
                    <div className="listprice">
                    <CurrencyRupeeIcon/>1650.00
                    </div>
                </div>
                <div className="listgroup">
                    <div className="listlabel">
                        Product
                        <ExpandMoreIcon sx={{ fontSize: 30 }}/>
                    </div>
                    <div className="listprice">
                        3 items
                    </div>
                </div>
                <div className="listgroup">
                    <div className="listlabel">
                        Order Number
                    </div>
                    <div className="listprice">
                        1234567890
                    </div>
                </div>
                <div className="listgroup">
                    <div className="listlabel">
                        Shipping & Handling
                    </div>
                    <div className="listprice">
                        <CurrencyRupeeIcon/>25.00
                    </div>
                </div>
                <div className="listgroup">
                    <div className="listlabel">
                        GST
                    </div>
                    <div className="listprice">
                        <CurrencyRupeeIcon/>05.00
                    </div>
                </div>
                <div className="listgroup">
                    <div className="listlabel">
                        Other Charges
                    </div>
                    <div className="listprice">
                        <CurrencyRupeeIcon/>15.00
                    </div>
                </div>
                <div className="totalprice">
                    <CurrencyRupeeIcon/>1695.00
                </div>
            </div>
        </Box>
    </Box>
  )
}

export default HomeHeader