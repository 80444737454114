import React from 'react'
import { Box } from '@mui/material'
import emi from '../../../assets/Icons Background (5).svg'
import ComingSoon from '../../pages/ComingSoon/ComingSoon'


function EmiComponent() {
  return (
    <div className="emi-section p-4" style={{fontFamily:'Jost'}}>
      <Box sx={{padding:'15px 5px'}}>
        <div className="flex items-center">
          <img src={emi} alt="" />
          <label className='text-lg font-semibold' htmlFor="credit" style={{color:'#252526'}}>EMI</label>
        </div>
      </Box>
      <ComingSoon/>
    </div>
  )
}

export default EmiComponent