import React from 'react'
import './PaymentCard.css'
import Sim from '../../assets/Frame 33.svg'
import Wifi from '../../assets/Frame 34.svg'
import visa from '../../assets/Frame35.svg'
import { useWindowSize } from '@react-hook/window-size'

function PaymentCard(props) {
  const {cardHolder,cardNumber,expiry} = props.creditvalues
  const [width, height] = useWindowSize()
  const items = [{ name: 'Item Total', value: '₹1650' },
  { name: 'Product', value: '3 Items' },
  { name: 'Order Number', value: '123456' },
  { name: 'GST', value: '₹25.00' },
  { name: 'SGST', value: '₹0.500' },
  { name: 'Shipping & Handling', value: '₹15' }]
  return (
    <div className='flex flex-col justify-around w-[30%] cursor-pointer rounded-3xl bg-orange-50 transition duration-300 ease-in-out mt-[-10px] px-8 relative'
    style={{
      height : height - ( height * 0.1)
    }}>
      <div className=' w-[237px] h-[260px] relative mx-auto top-[-41px] bg-gray-100 rounded-2xl paymentcard bg-no-repeat'>
        <div className='w-[70px] h-[20px] bg-red-600 absolute left-20 top-[-10px] -z-10'></div>
        <div className='w-[40px] h-[30px] flex relative'>
          <img src={Sim} className='object-contain absolute left-[22px] top-[10px]' />
          <img src={Wifi} className='object-contain absolute left-[192px] top-[10px] ' />
        </div>
        <div className='absolute top-[150px] left-[24px]'>
          
          {
            cardHolder !=='' ? <p className='uppercase font-semibold text-base'>{cardHolder}</p> : <p className='font-semibold text-base'>Card Holder Name</p>
          }
          <p className='font-semibold text-base  w-[180px] mt-1'>{cardNumber}</p>
        </div>
        <div className='absolute top-[230px] left-1 flex justify-between  w-full'>
          <div className='ml-5'>
            <p className='font-semibold text-base'>{expiry}</p>
          </div>
          <div className='h-[18px] w-[45px] mr-5'>
            <img src={visa} alt='visa' />
          </div>
        </div>
      </div>
      <div className='flow-root mt-[-10px]'>
        {items.map(item => (
          <ul className='divide-y divide-gray-200 dark:divide-gray-700' >
            <li className='py-2 2xl:py-4'>
              <div className='flex justify-between'>
                <p className='w-[150px] h-[15px] text-gray-400 font-medium text-sm'>{item.name}</p>
                <p className='font-semibold  h-[17px] text-sm'>{item.value}</p>
              </div>
            </li>
            <div className='h-[0.5px] bg-gray-300 ' />
          </ul>
        ))}
      </div>
      <div className='h-[50px] bg-[#f4c456] flex items-center justify-center rounded-md  gap-24 relative bottom-[0px]'>
        <div>
          <h3 className='font-semibold text-white text-xl'>Total</h3>
        </div>
        <div>
          <h3 className='font-semibold text-white text-xl'>₹1650.95</h3>
        </div>
      </div>
      {/* <div className='absolute bottom-[-15px] w-[80%] h-[50px] bg-[#f4c456] mt-10 flex items-center justify-center rounded-md  gap-24 '>
        <div>
          <h3 className='font-semibold text-white text-xl'>Total</h3>
        </div>
        <div>
          <h3 className='font-semibold text-white text-xl'>₹1650.95</h3>
        </div>
      </div> */}
    </div>
  )
}

export default PaymentCard