import React, { useState } from 'react'
import { Box, Divider, Select, MenuItem } from '@mui/material'
import netbankticon from '../../../assets/Icons Background (4).svg'
import axisbank from '../../../assets/Frame 48.svg'
import icicibank from '../../../assets/Frame 49.svg'
import sbibank from '../../../assets/Frame 52.svg'
import canarabank from '../../../assets/Frame 50.svg'
import southindianbank from '../../../assets/Frame 51.svg'
import hdfcbank from '../../../assets/Frame 53.svg'
import visa from '../../../assets/Frame35.svg'
import mastercard from '../../../assets/Frame 36.svg'
import pci from '../../../assets/Frame 37.svg'
import rupay from '../../../assets/Frame 38.svg'
import ShieldCheck from '../../../assets/ShieldCheck.svg'

function NetBankingComponent() {
    const apps = [
        { id: 1, src: axisbank, },
        { id: 2, src: icicibank },
        { id: 3, src: sbibank },
        { id: 4, src: canarabank },
        { id: 5, src: southindianbank },
        { id: 6, src: hdfcbank },
    ]
    const [bank, setBank] = useState('bank')
    const handleChange = (event) => {
        setBank(event.target.value);
    };
    return (
        <div className="creditcard-section px-4 pt-4" style={{fontFamily:'Jost',}}>
            <Box sx={{padding:'0px 15px'}}>
                <div className="flex items-center">
                <img src={netbankticon} alt="" />
                <label className='text-lg font-semibold' htmlFor="credit" style={{color:'#252526'}}>Net Banking</label>
                </div>
            </Box>
            <Box>
            <div className='grid grid-cols-3 justify-items-center'>
                {apps.map(app => (
                    <div key={app.id} className='h-[50px] w-[100px] border border-gray-200 my-5 flex items-center justify-center'>
                        <img src={app.src} alt=""/>
                    </div>
                ))}
            </div>
            </Box>
            
            <Box sx={{marginBottom:'15px', padding:'0px 13px'}}>
                <div>
                    <h3 className='mb-2 font-semibold text-base text-black'>Select Bank</h3>
                    <p className='mb-5 text-gray-400 w-[280px] h-[16px] font-semibold text-sm'>Find your bank from the list below</p>
                </div>
                <div className=''>
                    <Select
                        className='w-full border-gray-500 hover:bg-yellow-50 rounded-md h-[45px]'
                        labelId=""
                        id=""
                        value={bank}
                        label=""
                        onChange={handleChange}
                    >
                        <MenuItem value="bank" disabled>Select your bank</MenuItem>
                        <MenuItem value="Axis Bank">Axis Bank</MenuItem>
                        <MenuItem value="Icici Bank">Icici Bank</MenuItem>
                        <MenuItem value="SBI Bank">SBI Bank</MenuItem>
                        <MenuItem value="Canara Bank">Canara Bank</MenuItem>
                        <MenuItem value="South Indian Bank">South Indian Bank</MenuItem>
                        <MenuItem value="HDFC Bank">HDFC Bank</MenuItem>
                    </Select>
                    </div>
                <Divider/>
                <Box sx={{margin:'15px 0'}}>
                <div className="flex flex-row w-full">
                <div className='flex flex-row justify-between items-center w-1/2'>
                    <div className=''>
                    <img src={visa} alt="visa" style={{
                        width: '37.33px',
                        height: '16px'
                    }} />
                    </div>
                    <div className=''>
                    <img src={mastercard} alt="" style={{
                        width: '46.08px',
                        height: '16px'
                    }} />
                    </div>
                    <div className=''>
                    <img src={pci} alt="pci" style={{
                        width: '30.86px',
                        height: '16px'
                    }} />
                    </div>
                    <div className=''>
                    <img src={rupay} alt="rupay" style={{
                        width: '41px',
                        height: '16px'
                    }} />
                    </div>
                </div>
                <div className='flex flex-row justify-end items-center w-1/2'>
                    <div className=''>
                    <img src={ShieldCheck} alt="" />
                    </div>
                    <div className=' pl-2'>
                    <label htmlFor="" style={{
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '17px',
                        color:'#888888'
                    }}>Safe and secure payments.</label>
                    </div>
                </div>
                </div>
            </Box>
            <Divider/>
        
            <Box>
                <div className='grid place-items-center mt-10'>
                <label htmlFor="" style={{
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '14px',
                    color:'#888888'
                }}>2019-21 secure.pay10.com. All rights reserved</label>
                </div>
            </Box>
        
            <Box sx={{
                marginTop:'170px',
            }}>
                <button style={{
                    fontFamily:'Jost',
                    color:'#ffffff',
                    width:'100%',
                    background: '#F23322',
                    borderRadius: '10px',
                    fontWeight: 700,
                    fontSize: '18.3215px',
                    lineHeight: '26px',
                    height:'48px'
                }}>PAY NOW</button>
            </Box>
                
            </Box>
        </div>
    )
}

export default NetBankingComponent