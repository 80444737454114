import { useWindowSize } from '@react-hook/window-size'
import React, {useState} from 'react'
import CardDetails from '../../components/CreditCard/CardDetails'
import PaymentCard from '../../components/CreditCard/PaymentCard'
function CreditCard() {

  const [creditData, setCreditData] = useState({
    cardHolder : 'Card Holder Name',
    cardNumber : '0000 0000 0000 0000',
    expiry : 'MM/YY'
  })
  const [width, height] = useWindowSize()
  return (
    <div className='flex w-[80%] justify-evenly' style={{
      height : height - ( height * 0.1),
    }}>
      <CardDetails setCreditData={setCreditData}/>
      <PaymentCard creditvalues = {creditData} />
    </div>
  )
}

export default CreditCard