import React from 'react'
import WalletDetails from '../../components/Wallet/WalletDetails'
import WalletPaymentCard from '../../components/Wallet/WalletPaymentCard'
function Wallet() {
    return (
        <div className='flex w-[80%] justify-evenly'>
            <WalletDetails />
            <WalletPaymentCard />
        </div>
    )
}

export default Wallet