import { useWindowSize } from '@react-hook/window-size'
import React from 'react'
import Avatar from '@mui/material/Avatar';
import poweredby from '../../../assets/poweredby.png'
import frame from '../../../assets/Frame42.png'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

function ThankYou() {
  const [width, height] = useWindowSize()
  return (
    <div className='relative w-full'>
      <div className='w-full' style={{
        height: height * 0.1,
        background:'linear-gradient(90deg, #F23322 0%, #F2A71B 100%)',
      }}>
        <div className="flex h-full p-5 items-center">
          <div className="">
          <Avatar src='https://s3-alpha-sig.figma.com/img/cb0d/8aeb/7eb868ee899dbbc31d54eef8983bf26a?Expires=1651449600&Signature=hNSQ9jgcnSMgt6hMcIlM-2dQtYIs~yoPqwhA583kZ2LERnd2BFEv26ECZaCqUStdRhsH-RWlfoQTz~N9ZGUVNr6rD~5tJF7Wtnx~krp6UgHsndZZXKuxhlkZQ-KAayaFony98MbbQDF1MwxqozQmxMYc~Sg22Ai76RsOB-MwAlNdY-geQCawrQpq15qgc9AxYA3UfZJlssTUPohUZ~tP7BhwH3TABzSI7kBC921UN46lIcHfgLjkiSK2TLMEvbOjXoqe9FoGZZDbQgVJRNBl4Nlz1SjdZDgaJKtRwBaYkOJU6TVAl1sZx4tCqU5pqLhHZt1L~2myz37GMBNebQo2cw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA'
            alt='logo'
            sx={{
                Width :'35.56px',
                Height: '35.56px',
                boxShadow: '0px 6px 13px -3px rgb(108 107 108);'
            }} />
          </div>
          <div
              style={{
                  fontFamily: 'Jost',
                  fontSize: '24px',
                  fontWeight: 600,
                  lineHeight: '35px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: '#FFFFFF',
                  padding: '0px 18px'
              }}
            >
              <span>Miriyam Saree Store</span>
            </div>
        </div>
      </div>
      <div className='p-5 grid content-center' style={{
        height: height - (height * 0.1 + height * 0.1)
      }}>
        <div className="mb-5 flex flex-col justify-evenly items-center" style={{
          background: 'rgba(242, 51, 34, 0.1)',
          borderRadius: '14px',
          height: '202px'
        }}>
          <img style={{
            width: '68px',
            height: '68px'
          }} src={frame} alt="" />
          <p className='text-center' style={{
            fontFamily: 'Jost',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
          }}>Thank you, your payment has been successful. </p>
        </div>
        <div className="mt-5 p-5 flex flex-col justify-evenly items-center" style={{
          background: '#FFFFFF',
          border: '2px solid #00B894',
          boxSizing: 'border-box',
          borderRadius: '8px',
          height:'160px',
          fontFamily:'Jost'
        }}>
          <div className='flex w-full justify-between items-center flex-wrap'>
            <span className='text-gray-400 text-sm'>Transaction Date</span>
            <span className="text-sm">01/01/2022</span>
          </div>
          <div className='flex w-full justify-between items-center flex-wrap'>
            <span className='text-gray-400 text-sm'>Time</span>
            <span className="text-sm">13 : 01</span>
          </div>
          <div className='flex w-full justify-between items-center flex-wrap'>
            <span className='text-lg'>Total Amount</span>
            <span style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '26px',              
              color: '#242424',
            }}><CurrencyRupeeIcon fontSize='small'/>1695.00</span>
          </div>
        </div>
      </div>
      <div className='grid  place-items-center' style={{
        height: height * 0.2,
        position: 'absolute',
        top: height - height * 0.2,
        width:width
      }}>
          <img src={poweredby} alt="Powered By" />
      </div>
    </div>
  )
}

export default ThankYou