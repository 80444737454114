import React from 'react'
import WaitingImage from '../../assets/Frame45.png'

function WaitingPayment() {
    return (
        <div className='flex-col px-20 py-10'>
            <div className=' w-[700px] h-[450px]  bg-red-100  rounded-lg'>
                <div class="px-40 py-20 bg-red-100 rounded-md ">
                    <div class="flex flex-col items-center">
                        {/* <h1 class="font-bold text-red-400 text-9xl">404</h1> */}
                        <img src={WaitingImage} alt='waiting payment' />
                        <h6 class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                            Waiting
                        </h6>

                        <p class="mb-8 text-center  text-gray-600 font-semibold md:text-lg">
                            Waiting for payment confirmation
                        </p>
                        <p class='mb-10 text-gray-600 font-semibold text-sm text-center'>
                            We are waiting for the payment confirmation from the server.
                            Donot refresh / reload the page- it may break
                            the request.
                        </p>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaitingPayment