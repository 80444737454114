import React, { useEffect, useState } from 'react'
import paytm from '../../assets/Frame 55.svg'
import phonepay from '../../assets/Frame 58.svg'
import amazonepay from '../../assets/Frame 56.svg'
import gpay from '../../assets/Frame 57.svg'
import upi from '../../assets/image58.svg'
import freerecharge from '../../assets/Frame 59.svg'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ShieldCheck from '../../assets/ShieldCheck.svg'
import mastercard from '../../assets/Frame 36.svg'
import rupay from '../../assets/Frame 38.svg'
import npci from '../../assets/Frame 39.svg'
import pov from '../../assets/Frame 37.svg'
import visa from '../../assets/Frame35.svg'
import logo from '../../assets/Frame 32.svg'
function UpiApps({setUpiValue}) {
    const [upiId, setUpiId] = useState('************')
    const [upis, setUpi] = React.useState('@okupiid');

    const handleChange = (event) => {
        setUpi(event.target.value);
    };
    const apps = [
        { id: 1, src: paytm },
        { id: 2, src: phonepay },
        { id: 3, src: amazonepay },
        { id: 4, src: gpay },
        { id: 5, src: upi },
        { id: 6, src: freerecharge },]
    
    useEffect(()=>{
        setUpiValue(`${upiId}${upis}`)
    },[upiId,upis])
    return (
        <div className='flex-col  mt-1 2xl:space-y-12 space-y-4 w-[50%] 2xl:w-[45%]'>
            <div className='grid grid-cols-3 w-full ml-[20px]'>
                {apps.map(app => (
                    <div className='h-[40px] w-[120px] border border-gray-200 mx-5 my-5 flex items-center justify-center'>
                        <img src={app.src} />
                    </div>
                ))}
            </div>
            <div className='flex-col space-y-2'>
                <div className='flex-col space-y-2 ml-4'>
                    <h3 className=' w-[200px] h-[20px]  font-semibold text-base text-black'>Virtual Payment Address</h3>
                    <p className=' text-gray-400 w-[280px] h-[16px] font-semibold text-sm'>UPI id</p>
                </div>
                <div className=' w-full  flex justify-between ml-4'>
                    <input onChange={e => setUpiId(e.target.value)} className="border focus:outline-none h-[40px] border-gray-500 rounded-sm inline-block py-3 px-3 w-[250px] text-gray-600  hover:bg-yellow-50" type="text" placeholder="example" />
                    <Select
                        className='w-[200px] border-gray-500 focus:outline-none hover:bg-yellow-50 h-[42px] rounded-md'
                        labelId=""
                        id=""
                        value={upis}
                        displayEmpty
                        onChange={handleChange}
                    >
                        <MenuItem value={'@okupiid'}>@okupiid</MenuItem>
                        <MenuItem value={'@okhdfc'}>@okhdfc</MenuItem>
                        <MenuItem value={'@okicici'}>@okicici</MenuItem>
                    </Select>
                </div>
                <div className=' w-full  flex justify-end ml-4'>
                    <button className='w-[200px] h-[42px]  bg-[#F23322]  rounded-md text-white'>
                        Verify ID</button>
                </div>
                <div className='w-full h-[40px] ml-4'>
                    <p className='text-gray-400 text-xs font-normal'>
                        VPA is a unique payment address that can be linked to a persons <br />
                        bank account to make payments
                    </p>
                </div>
                <div className='flex justify-between pt-4 space-x-4 ml-4'>
                    <div className='w-[120px] h-[17px] flex justify-between space-x-2'>

                        <img src={mastercard}
                            alt="master"
                        />
                        <img src={pov}
                            alt="pov"
                            className='h-[25px]'
                        />
                        <img src={npci}
                            alt="rupay"
                        />
                        <img src={rupay}
                            alt="pay"
                        />
                    </div>
                    <div className=' w-[182px] h-[17px] flex items-center'>
                        <img src={ShieldCheck} />
                        <p className='text-gray-400 text-xs'>Safe and secure payments.</p>
                    </div>
                </div>
                <div className='h-[1px] w-full  bg-gray-300' />
                <div className=' h-[14px]  flex justify-center'>
                    <p className='text-gray-400 text-xs text-center'>2019-21 secure.pay10.com. All rights reserved</p>
                </div>
                {/* Buttons */}
                <div className='flex justify-center pt-[30px]'>
                    <button className='uppercase  w-[402px] h-[40.63px]  bg-[#F23322]  rounded-lg text-white'>
                        PAY NOW</button>
                </div>
                {/* Powered by */}
                <div className=' w-full h-[17px]  flex justify-center items-center'>
                    <div className=''>
                        <img src={logo}
                            alt='pay10 logo'
                            className='w-[140px] h-[17px]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpiApps