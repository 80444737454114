import React from 'react'
import ErrorImage from '../../assets/Frame47.png'
function ErrorFiveZeroFive() {
    return (
        <div className='flex-col px-20 py-10'>
            <div className=' w-[700px] h-[450px]  bg-red-100 rounded-lg'>
                <div class="px-40 py-20 bg-red-100 rounded-md ">
                    <div class="flex flex-col items-center">
                        {/* <h1 class="font-bold text-red-400 text-9xl">404</h1> */}
                        <img src={ErrorImage} alt='502' />
                        <p class="mt-16 text-center text-gray-600 font-semibold md:text-lg">
                            Bad Gateway Error
                        </p>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default ErrorFiveZeroFive