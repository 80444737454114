import './App.css';
import Navbar from './components/Navbar';
import { Routes, Route } from 'react-router-dom'
import CreditCard from './pages/creditcard/CreditCard';
import DebitCard from './pages/debitcard/DebitCard';
import Upi from './pages/UPI/Upi';
import Header from './components/Header';
import Wallet from './pages/wallets/Wallet';
import NetBanking from './pages/netbanking/NetBanking';
import Emi from './pages/EMI/Emi';
import { useEffect, useState } from 'react'
import MobApp from './mobile/MobApp';
import PageNotFound from './components/error/PageNotFound'
import BadGateway from './components/error/BadGateWay';
import NoInternet from './components/error/NoInternet';
import WaitingPayment from './components/error/WaitingPayment'
import PaymentApproval from './components/approval/PaymentApproval'
import PaymentRejected from './components/error/PaymentRejected';
import Bnpl from './pages/bnpl/Bnpl';
import { useWindowSize } from '@react-hook/window-size';
function App() {
  const [width, height] = useWindowSize()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakpoint = 1100;
  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth), false);
  })
  return screenWidth < breakpoint ? (<MobApp />) : (
    <div className='web-payten' style={{
      height :height - (height * 0.01), 
    }}>
      <div style={{
        height :height * 0.1,
        width
      }}>
        <Header />
      </div>
      <main className='max-w-screen flex mx-auto' style={{
        height :height - (height * 0.1),
        width
      }}>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<CreditCard />} />
          <Route path='/debit' element={<DebitCard />} />
          <Route path='/upi' element={<Upi />} />
          <Route path='/wallet' element={<Wallet />} />
          <Route path='/netbanking' element={<NetBanking />} />
          <Route path='/emi' element={<Emi />} />
          <Route path='/bnpl' element={<Bnpl />} />
          <Route path='/pagenotfound' element={<PageNotFound />} />
          <Route path='/badgateway' element={<BadGateway />} />
          <Route path='/nointernet' element={<NoInternet />} />
          <Route path='/waiting' element={<WaitingPayment />} />
          <Route path='/success' element={<PaymentApproval />} />
          <Route path='/rejected' element={<PaymentRejected />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
