import { Box, TextField, Divider } from '@mui/material'
import React, { useState} from 'react'
import creditcard from '../../../assets/Icons Background(1).svg'
import visa from '../../../assets/Frame35.svg'
import mastercard from '../../../assets/Frame 36.svg'
import pci from '../../../assets/Frame 37.svg'
import rupay from '../../../assets/Frame 38.svg'
import ShieldCheck from '../../../assets/ShieldCheck.svg'

function Debit() {
  const [cardNumber, setCardNumber] = useState("");
  const [cvvNumber, setCvvNumber] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")
  const [name, setName] = useState("")

  const formatAndSetCcNumber = e => {
    const inputVal = e.target.value.replace(/ /g, ""); 
    let inputNumbersOnly = inputVal.replace(/\D/g, ""); 

    if (inputNumbersOnly.length > 19) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 19);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  "); // Join all the splits with an empty space
    }

    spacedNumber !== '' ? setCardNumber(spacedNumber) : setCardNumber('');
  };
  const formatAndSetCcNumber2 = (e)=>{
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, ""); 

    if (inputNumbersOnly.length === 17 || inputNumbersOnly.length === 18) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  "); 
    }
    spacedNumber !== '' ? setCardNumber(spacedNumber) : setCardNumber('');
  }
  const formatCvvNumber = e => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, ""); 

    if (inputNumbersOnly.length > 3) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 3);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  ");
    }
    spacedNumber !== '000' ? setCvvNumber(spacedNumber) : setCvvNumber('')
  }
  const formatMonth = e => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 2) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 2);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  ");
    }
    const d = new Date();
    let mnth = d.getMonth() + 1
    let twodigityear = d.getFullYear().toString().substr(-2)
    if(twodigityear === year){
      if(spacedNumber.length === 2){
        if(parseInt(spacedNumber) < mnth ){
          setMonth('MM')
          return false
        }
      }else if(parseInt(spacedNumber) !== 1 && parseInt(spacedNumber) !== 0 ){
        if(parseInt(spacedNumber) < mnth ){
          setMonth('MM')
          return false
        }
      }

      if(spacedNumber <= 12){
        spacedNumber !== '' ? setMonth(spacedNumber) : setMonth('MM')
      }else{
        setMonth('MM')
      }
    }else{
      if(spacedNumber <= 12){
        spacedNumber !== '' ? setMonth(spacedNumber) : setMonth('MM')
      }else{
        setMonth('MM')
      }
    }
  }
  const formatYear = e => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 2) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 2);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("  ");
    }
    const d = new Date();
    if(spacedNumber.length === 2){
      let year = d.getFullYear().toString().substr(-2)
      if(parseInt(year) > spacedNumber){
        spacedNumber =''
      }
      else{
        let mnth = d.getMonth() + 1
        if(month < mnth){
          setMonth('MM')
        }
      }
    }
    spacedNumber !== ''? setYear(spacedNumber) : setYear('YY')
  }

  const setname = (e)=>{

    if(e.target.value !== ''){
      let inputNumbersOnly = e.target.value.replace(/[!\@\^\_\&\/\\#,\|+()$~%.'":*?<>{}\d]/g, '');
      let inputVal = inputNumbersOnly; 
      if (inputVal.length > 50) {
        inputVal = inputVal.substr(0, 50);
      }
      setName(inputVal)
    }else{
      setName('')
    }
  }

  return (
    <div className="creditcard-section p-4" style={{fontFamily:'Jost'}}>
      <Box sx={{padding:'15px 5px'}}>
        <div className="flex items-center">
          <img src={creditcard} alt="" />
          <label className='text-lg font-semibold' htmlFor="credit" style={{color:'#252526'}}>Debit Card Details</label>
        </div>
      </Box>
      <Box sx={{marginBottom:'15px'}}>
        <div className="grid">
          <label className='pb-1 text-lg font-semibold' htmlFor="card number" style={{color:'#3E4036'}}>Card Number</label>
          <label className='pb-4 text-sm font-semibold' htmlFor="subcontent" style={{color:'#888888'}}>Enter the 16-digit card number, on your card</label>
          <TextField size='small' sx={{
            // border: '1px solid rgba(68, 68, 69, 0.5)',
            boxSizing: 'border-box',
            borderRadius: '3.66429px',
          }} placeholder="0000 0000 0000 0000" onChange={formatAndSetCcNumber} onBlur={formatAndSetCcNumber2} value={cardNumber}/>
        </div>
      </Box>
      <Box sx={{marginBottom:'15px'}}>
        <div className="grid">
          <label className='pb-1 text-lg font-semibold' htmlFor="card number" style={{color:'#3E4036'}}>CVV</label>
          <p className='pb-4 text-sm font-semibold' htmlFor="subcontent" style={{color:'#888888'}}>Enter 3 digit number on your card( front side for amex, back for others). Security code for Amex.</p>
          <TextField size='small' type="password" inputProps={{ maxLength: 3 }} sx={{
            // border: '1px solid rgba(68, 68, 69, 0.5)',
            boxSizing: 'border-box',
            borderRadius: '3.66429px',
          }} placeholder="000" value={cvvNumber} onChange={formatCvvNumber} />
        </div>
      </Box>
      <Box sx={{marginBottom:'15px'}}>
        <div className="grid">
          <label className='pb-1 text-lg font-semibold' htmlFor="card number" style={{color:'#3E4036'}}>Expiry</label>
          <label className='pb-4 text-sm font-semibold' htmlFor="subcontent" style={{color:'#888888'}}>Enter the expiry date on the card</label>
          <div className="flex justify-between ">
            <TextField size='small' sx={{
              // border: '1px solid rgba(68, 68, 69, 0.5)',
              boxSizing: 'border-box',
              borderRadius: '3.66429px',
              width:'48%'
            }} placeholder="MM" value={month} onChange={formatMonth}/>
            <TextField size='small' sx={{
              // border: '1px solid rgba(68, 68, 69, 0.5)',
              boxSizing: 'border-box',
              borderRadius: '3.66429px',
              width:'48%'
            }} placeholder="YY" value={year} onChange={formatYear}/>

          </div>
        </div>
      </Box>
      <Box sx={{marginBottom:'20px'}}>
        <div className="grid">
          <label className='pb-1 text-lg font-semibold' htmlFor="card number" style={{color:'#3E4036'}}>Card Holder Name</label>
          <label className='pb-4 text-sm font-semibold' htmlFor="subcontent" style={{color:'#888888'}}>Enter name on the card</label>
          <TextField size='small' inputProps={{ style: { textTransform: "uppercase" } }} sx={{
            // border: '1px solid rgba(68, 68, 69, 0.5)',
            boxSizing: 'border-box',
            borderRadius: '3.66429px'
          }} placeholder="Card holder name" onChange={setname} value={name}/>
        </div>
      </Box>
      <Divider/>
      
      <Box sx={{margin:'15px 0'}}>
        <div className="flex flex-row w-full">
          <div className='flex flex-row justify-between items-center w-1/2'>
            <div className=''>
              <img src={visa} alt="visa" style={{
                width: '37.33px',
                height: '16px'
              }} />
            </div>
            <div className=''>
              <img src={mastercard} alt="" style={{
                width: '46.08px',
                height: '16px'
              }} />
            </div>
            <div className=''>
              <img src={pci} alt="pci" style={{
                width: '30.86px',
                height: '16px'
              }} />
            </div>
            <div className=''>
              <img src={rupay} alt="rupay" style={{
                width: '41px',
                height: '16px'
              }} />
            </div>
          </div>
          <div className='flex flex-row justify-end items-center w-1/2'>
            <div className=''>
              <img src={ShieldCheck} alt="" />
            </div>
            <div className=' pl-2'>
              <label htmlFor="" style={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '17px',
                color:'#888888'
              }}>Safe and secure payments.</label>
            </div>
          </div>
        </div>
      </Box>
      <Divider/>

      <Box>
        <div className='grid place-items-center mt-10'>
          <label htmlFor="" style={{
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '14px',
            color:'#888888'
          }}>2019-21 secure.pay10.com. All rights reserved</label>
        </div>
      </Box>

      <Box sx={{
        marginTop:'150px',
      }}>
        <button style={{
          fontFamily:'Jost',
          color:'#ffffff',
          width:'100%',
          background: '#F23322',
          borderRadius: '10px',
          fontWeight: 700,
          fontSize: '18.3215px',
          lineHeight: '26px',
          height:'48px'
        }}>PAY NOW</button>
      </Box>

    </div>
  )
}

export default Debit