import React from 'react'
import HomeFooter from '../../components/HomeFooter/HomeFooter'
import HomeHeader from '../../components/HomeHeader/HomeHeader'
import HomeMenus from '../../components/HomeMenus/HomeMenus'

function Home() {
  return (
    <main className='flex gap-2 flex-col'>
      <HomeHeader/>
      <HomeMenus/>
      <HomeFooter/>
    </main>
    
  )
}

export default Home