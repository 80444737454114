import React from 'react'
import Footer from '../../layouts/Footer/Footer'
import Header from '../../layouts/Header/Header'
import UpiComponent from '../../components/Upi/UpiComponent'

function Upi() {
  return (
    <div>
        <Header/>
        <UpiComponent/>
        <Footer/>
    </div>
  )
}

export default Upi