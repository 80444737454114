import React from 'react'
import EmiComponent from '../../components/EMI/EmiComponent'
import Footer from '../../layouts/Footer/Footer'
import Header from '../../layouts/Header/Header'

function Emi() {
  return (
    <div>
        <Header/>
        <EmiComponent/>
        <Footer/>
    </div>
  )
}

export default Emi