import React from 'react'
import Debit from '../../components/Debit/Debit'
import Footer from '../../layouts/Footer/Footer'
import Header from '../../layouts/Header/Header'
function DebitCard() {
  return (
    <div>
        <Header/>
        <Debit/>
        <Footer/>
    </div>
  )
}

export default DebitCard