import React from 'react'
import Box from '@mui/material/Box';
import { Divider, List, ListItem } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import creditcard from '../../../assets/IconsBackground.svg'
import debittcard from '../../../assets/Icons Background(1).svg'
import upi from '../../../assets/Icons Background (2).svg'
import wallet from '../../../assets/Icons Background (3).svg'
import netbanking from '../../../assets/Icons Background (4).svg'
import emi from '../../../assets/Icons Background (5).svg'
import booknow from '../../../assets/Bnpl icon.svg'
import { Link } from 'react-router-dom';

function HomeMenus() {
  return (
    <div className="px-6" style={{fontFamily:'Jost'}}>
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column'
        }}
        >
            <List>
                <Link to='credit-card'>
                    <ListItem sx={{
                        padding:'15px 0',
                        display: 'flex'
                    }}>
                        <div>
                            <img src={creditcard} alt="" />
                        </div>
                        <div className='pl-6 text-lg font-semibold'>Credit Card</div>
                        <div className='absolute right-0'><ArrowForwardIosIcon sx={{ fontSize: 20 }}/></div>
                    </ListItem>
                </Link>
                <Divider/>
                <Link to='debit-card'>
                    <ListItem sx={{
                        padding:'15px 0',
                        display: 'flex'
                    }}>
                        <div>
                        <img src={debittcard} alt="" />
                        </div>
                        <div className='pl-6 text-lg font-semibold'>Debit Card</div>
                        <div className='absolute right-0'><ArrowForwardIosIcon sx={{ fontSize: 20 }}/></div>
                    </ListItem>
                </Link>
                <Divider/>
                <Link to='upi'>
                    <ListItem sx={{
                        padding:'15px 0',
                        display: 'flex'
                    }}>
                        <div>
                        <img src={upi} alt="" />
                        </div>
                        <div className='pl-6 text-lg font-semibold'>UPI</div>
                        <div className='absolute right-0'><ArrowForwardIosIcon sx={{ fontSize: 20 }}/></div>
                    </ListItem>
                </Link>
                <Divider/>
                <Link to='wallet'>
                    <ListItem sx={{
                        padding:'15px 0',
                        display: 'flex'
                    }}>
                        <div>
                        <img src={wallet} alt="" />
                        </div>
                        <div className='pl-6 text-lg font-semibold'>Wallet</div>
                        <div className='absolute right-0'><ArrowForwardIosIcon sx={{ fontSize: 20 }}/></div>
                    </ListItem>
                </Link>
                <Divider/>
                <Link to='net-banking'>
                    <ListItem sx={{
                        padding:'15px 0',
                        display: 'flex'
                    }}>
                        <div>
                        <img src={netbanking} alt="" />
                        </div>
                        <div className='pl-6 text-lg font-semibold'>Net Banking</div>
                        <div className='absolute right-0'><ArrowForwardIosIcon sx={{ fontSize: 20 }}/></div>
                    </ListItem>
                </Link>
                <Divider/>
                <Link to='emi'>
                    <ListItem sx={{
                        padding:'15px 0',
                        display: 'flex'
                    }}>
                        <div>
                        <img src={emi} alt="" />
                        </div>
                        <div className='pl-6 text-lg font-semibold'>EMI</div>
                        <div className='absolute right-0'><ArrowForwardIosIcon sx={{ fontSize: 20 }}/></div>
                    </ListItem>
                </Link>
                
                <Divider/>
                <Link to='book-now-pay-later'>
                    <ListItem sx={{
                        padding:'15px 0',
                        display: 'flex'
                    }}>
                        <div>
                        <img src={booknow} alt="" />
                        </div>
                        <div className='pl-6 text-lg font-semibold'>Book Now Pay Later (BNPL)</div>
                        <div className='absolute right-0'><ArrowForwardIosIcon sx={{ fontSize: 20 }}/></div>
                    </ListItem>
                </Link>
                
            </List>
        </Box>
    </div>
  )
}

export default HomeMenus