import React from 'react'
import { Box } from '@mui/material'
import frame from '../../../assets/banknameerror.png'

function BankNameError() {
  return (
    <div className="h-screen flex items-center p-1">
        <div className="p-5 flex flex-col items-center justify-evenly flex-wrap" style={{
            background: '#FFFFFF',
            border: '2px solid #F23322',
            boxSizing: 'border-box',
            borderRadius: '8px',
            height: '444px'
        }}>
            <img style={{
              width: '68px',
              height: '68px'
            }} src={frame} alt="" />
            <p style={{
              fontFamily: 'Jost',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '26px',
              textAlign: 'center',
              color:'#F2A71B'
            }}>
                Your (Bank Name) is currently
                experiencing a problem
            </p>
            <p style={{
              fontFamily: 'Jost',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '23px',
              textAlign: 'center',
              color:'#444445'
            }}>
                We suggest you to come back and try again 
                after some time or use another bank / payment 
                method to transfer money.
            </p>
            
            <Box sx={{
                width:'100%'
            }}>
                <button style={{
                    fontFamily:'Jost',
                    color:'#ffffff',
                    width:'100%',
                    background: '#F23322',
                    borderRadius: '4px',
                    fontWeight: 700,
                    fontSize: '18px',
                    lineHeight: '26px',
                    height:'48px'
                }}>OK</button>
            </Box>
        </div>
    </div>
  )
}

export default BankNameError